<template>
  <div class="page-forms-create">
    <div class="columns is-gapless is-vh">
      <div class="column is-2 has-background-dark">
        <Sidebar/>
      </div>

      <div class="column">
        <div class="section">
          <div class="buttons">
            <b-button tag="router-link"
              :to="'/forms'"
              type="is-primary"
              icon-left="chevron-left">
              Back to Overview
            </b-button>
          </div>

          <h1 class="title">Create form</h1>
          <hr>

          <FormCreateForm :afterRedirect="afterRedirect" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import FormCreateForm from '@/components/forms/FormCreateForm.vue'

export default {
  name: 'FormsCreate',
  components: {
    Sidebar,
    FormCreateForm,
  },
  data: function() {
    return {
      afterRedirect: {
        name: 'forms',
      },
    };
  },
}
</script>
